import React from "react";
import { message } from "antd";
import { CopyOutlined } from "@ant-design/icons";

export default function CopyText({ text, style }) {
    let txt = "";

    const copyToClipboard = (e) => {
        e.stopPropagation();

        if (typeof text == "object") {
            txt = text.props.text;
        } else {
            txt = text;
        }

        navigator.clipboard
            .writeText(txt)
            .then(() => {
                message.success("Text copied to clipboard");
            })
            .catch(() => {
                message.error("Failed to copy text to clipboard");
            });
    };

    return (
        <span
            style={{
                cursor: "pointer",
                color: "#1890ff",
                display: "flex",
                alignItems: "center",
                gap: 10,
                margin: 0,
                padding: 0,
                maxWidth: "170px",
            }}
            onClick={(e) => copyToClipboard(e)}
        >
            {text} <CopyOutlined style={style} />
        </span>
    );
}
