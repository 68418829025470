import { useEffect, useState } from "react";
import Request from "../../Requests";
import { Drawer, Form, Button, notification, Input, Checkbox } from "antd";

export default function AdminsDrawer({ visible, onClose, selectedRow }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (visible) {
            if (selectedRow) {
                form.setFields([
                    { name: "edit_playlist", value: selectedRow?.permission?.edit_playlist },
                    {
                        name: "deactivate_device",
                        value: selectedRow?.permission?.deactivate_device,
                    },
                    { name: "edit_reseller", value: selectedRow?.permission?.edit_reseller },
                    { name: "manage_credits", value: selectedRow?.permission?.manage_credits },
                    { name: "manage_admins", value: selectedRow?.permission?.manage_admins },
                ]);
            }
        } else {
            form.resetFields();
        }
    }, [visible, selectedRow]);

    const onFinish = (data) => {
        let values = {};

        setLoading(true);

        for (const key in data) {
            if (data[key] !== "") values[key] = data[key];
        }

        if (selectedRow) {
            values.id = selectedRow.id;
        }

        Request.admins
            .put(values)
            .then(() => {
                setLoading(false);
                hideDrawer(true);
            })
            .catch(({ message }) => {
                setLoading(false);
                notification.error({ message });
            });
    };

    const hideDrawer = (changed) => {
        onClose(changed);
        form.resetFields();
    };

    return (
        <Drawer
            title={selectedRow ? "Edit" : "Add"}
            placement="right"
            onClose={() => hideDrawer(false)}
            open={visible}
            width={600}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
                itialValues={{
                    edit_playlist: selectedRow?.edit_playlist,
                    deactivate_device: selectedRow?.deactivate_device,
                    edit_reseller: selectedRow?.edit_reseller,
                    manage_credits: selectedRow?.manage_credits,
                    manage_admins: selectedRow?.manage_admins,
                }}
            >
                <Form.Item
                    label="Password"
                    name="password"
                    style={{
                        marginBottom: 10,
                    }}
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item
                    label="Confirm password"
                    name="confirm_password"
                    style={{
                        marginBottom: 10,
                    }}
                >
                    <Input.Password placeholder="Confirm password" />
                </Form.Item>
                <Form.Item
                    label=""
                    name="edit_playlist"
                    valuePropName="checked"
                    style={{
                        marginBottom: 10,
                    }}
                >
                    <Checkbox> Edit playlist</Checkbox>
                </Form.Item>
                <Form.Item
                    label=""
                    name="deactivate_device"
                    valuePropName="checked"
                    style={{
                        marginBottom: 10,
                    }}
                >
                    <Checkbox> Deactivate device</Checkbox>
                </Form.Item>
                <Form.Item
                    label=""
                    name="edit_reseller"
                    valuePropName="checked"
                    style={{
                        marginBottom: 10,
                    }}
                >
                    <Checkbox> Edit reseller</Checkbox>
                </Form.Item>
                <Form.Item
                    label=""
                    name="manage_credits"
                    valuePropName="checked"
                    style={{
                        marginBottom: 10,
                    }}
                >
                    <Checkbox> Manage credits</Checkbox>
                </Form.Item>
                <Form.Item
                    label=""
                    name="manage_admins"
                    valuePropName="checked"
                    style={{ marginBottom: 10 }}
                >
                    <Checkbox> Manage admins</Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        onClick={form.submit}
                        style={{ marginRight: 10 }}
                        loading={loading}
                    >
                        Update
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
}
