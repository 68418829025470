import { useEffect, useState } from "react";
import {
  Form,
  Button,
  notification,
  Input,
  Modal,
  Tooltip,
  Checkbox,
  Select,
} from "antd";
import { createFieldsValue } from "../../Utils";
import Request from "../../Requests";
import {
  ExclamationCircleFilled,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";

import { ConfirmComponent } from "../../components";

import CreditDrawer from "./CreditDrawer";

export default function ResellerInfo({
  theProviderId,
  providerInfo,
  getProviderInfo,
}) {
  const [form] = Form.useForm();

  const [isPartner, setIsPartner] = useState(false);
  const [options, setOptions] = useState([]);

  const [blockReseller, setBlockReseller] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const [credit, setCredit] = useState({
    show: false,
    type: "",
  });

  const [loading, setLoading] = useState(false);

  const [profile, setProfile] = useState(null);

  const [createdByAdmin, setCreatedByAdmin] = useState(null);

  useEffect(() => {
    if (providerInfo && theProviderId) {
      if (providerInfo.password) {
        delete providerInfo.password;
        form.setFieldsValue(createFieldsValue(providerInfo, fields));
      } else {
        form.setFieldsValue(createFieldsValue(providerInfo, fields));
      }
    }
  }, [providerInfo, theProviderId]);

  useEffect(() => {
    getProfile();
    getData();
    getAdminsData();
    setBlockReseller(providerInfo?.blocked);
  }, []);

  const getAdminsData = () => {
    Request.admins
      .get()
      .then((data) => {
        setLoading(false);
        const admin = data.find((admin) => admin.id === providerInfo?.admin_id);
        if (admin) {
          const fullName = `${admin.name || ""} ${admin.surname || ""}`.trim();
          setCreatedByAdmin(fullName || admin.email);
        } else {
          setCreatedByAdmin("The reseller completed their own registration");
        }
      })
      .catch(({ message }) => {});
  };

  useEffect(() => {
    if (providerInfo?.is_partner) {
      setIsPartner(true);
    } else {
      setIsPartner(false);
    }
  }, []);

  const validateBrandName = (rule, value, callback) => {
    const regex = /^[a-zA-Z0-9]+$/;
    if (
      (typeof value === "string" || typeof value === "number") &&
      regex.test(value)
    ) {
      callback();
      setDisabled(false);
    } else {
      callback("Brand name must include only letters or numbers");
      setDisabled(true);
    }
  };

  const fields = {
    id: {
      type: "INTEGER",
      rules: [{ required: false, message: "" }],
    },
    created_by: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },
    brand_name: {
      type: "STRING",
      rules: [
        { required: false, message: "" },
        { validator: validateBrandName },
      ],
    },
    app_name: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },
    partner_key: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },
    email: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },
    server_host: {
      type: "STRING",
      rules: [{ required: false, message: "" }],
    },

    password: { type: "STRING", rules: [{ required: false, message: "" }] },

    is_partner: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },
    is_seller: { type: "BOOLEAN", rules: [{ required: false, message: "" }] },

    partner_package_id: {
      type: "NUMBER",
      rules: [{ required: false, message: "" }],
    },
    phone_number: {
      type: "NUMBER",
      rules: [{ required: false, message: "" }],
    },
  };

  const getProfile = () => {
    Request.profile()
      .then((data) => {
        setProfile(data);
      })
      .catch((err) => {});
  };

  const getData = () => {
    const body = {
      query: JSON.stringify({
        page: 1,
        limit: 100,
      }),
    };

    Request.partner_packages.get(body).then((data) => {
      const filteredPackages = data.rows.filter((item) => {
        return !item.archived;
      });

      const options = filteredPackages.map((item) => {
        return {
          label: `${item.name} ( Activations ${item.device_count} ) $${item.price} `,
          value: item.id,
        };
      });

      setOptions(options);
    });
  };

  const onFinish = (values) => {
    setLoading(true);
    const body = {
      brand_name: values.brand_name,
      app_name: values.app_name,
      server_host: values.server_host,
      is_partner: isPartner,
      is_seller: values.is_seller,
    };

    if (values.phone_number) {
      body.phone_number = values.phone_number;
    }

    if (values.is_seller && isPartner) {
      body.is_seller = values.is_seller;
    } else {
      body.is_seller = false;
    }

    if (isPartner) {
      body.partner_package_id = values.partner_package_id;
    }

    if (providerInfo) {
      body.id = theProviderId;
    }
    if (providerInfo.email !== values.email) {
      body.email = values.email;
    }

    if (values.password) {
      body.password = values.password;
    }

    Modal.confirm({
      title: "Do you want to change these info?",
      icon: <ExclamationCircleFilled />,
      onOk() {
        Request.provider
          .put(body)
          .then(() => {
            setLoading(false);
            notification.success({ description: "Reseller info updated!" });
            getProviderInfo();
          })
          .catch(({ message }) => {
            setLoading(false);
            notification.error({ message });
          });
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (changedFields[0].name[0] === "is_partner") {
      if (changedFields[0].value === false) {
        form.setFieldsValue({ partner_package_id: null });
        form.setFieldsValue({ is_seller: false });
      }
    }
  };

  const onChangeResellerType = (value) => {
    if (value == "reseller") {
      setIsPartner(false);
    } else {
      setIsPartner(true);
    }
  };
  const returnPackageAndCreditsComponents = () => {
    return isPartner
      ? profile?.permission?.edit_reseller && (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "320px",
              }}
            >
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item name="partner_package_id" label="Packages">
                      <Select
                        options={options}
                        disabled={!isPartner}
                        style={{ width: "250px" }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </div>
            <div>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      label=""
                      name="is_seller"
                      valuePropName="checked"
                      disabled={
                        !getFieldValue("is_partner") || providerInfo.is_limited
                      }
                    >
                      <Checkbox
                        disabled={!getFieldValue("is_partner")}
                        style={{
                          width: "max-content!important",
                        }}
                      >
                        Seller
                      </Checkbox>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </div>
          </div>
        )
      : profile?.permission?.manage_credits && (
          <div className="reseller-info-list">
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                margin: "10px",
                gap: "17px",
              }}
            >
              <h2>Credit: {providerInfo?.credit}</h2>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "14px",
                }}
              >
                <Tooltip title="Take credit">
                  <Button
                    onClick={() => {
                      setCredit({
                        show: true,
                        type: "take",
                      });
                    }}
                    type="primary"
                    danger
                    ghost
                    icon={<MinusOutlined />}
                  />
                </Tooltip>
                <Tooltip title="Add credit">
                  <Button
                    onClick={() => {
                      setCredit({
                        show: true,
                        type: "add",
                      });
                    }}
                    type="primary"
                    ghost
                    icon={<PlusOutlined />}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        );
  };

  const onBlock = () => {
    if (theProviderId) {
      Request.provider
        .block({ id: theProviderId, blocked: !blockReseller })
        .then((res) => notification.success({ description: res }))
        .catch((err) => notification.error({ description: err }));
    }
  };
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        autoComplete="off"
        className="reseller-info-form"
      >
        <div>
          <p>Created by</p>
          <p>{createdByAdmin}</p>
        </div>
        <Form.Item
          label="Brand name"
          name="brand_name"
          rules={fields.brand_name.rules}
        >
          <Input placeholder="brand name" style={{ maxWidth: 500 }} />
        </Form.Item>

        <Form.Item
          label="App name"
          name="app_name"
          rules={fields.app_name.rules}
        >
          <Input placeholder="App name" style={{ maxWidth: 500 }} />
        </Form.Item>

        <Form.Item
          label="Partner key"
          name="partner_key"
          rules={fields.partner_key.rules}
        >
          <Input placeholder="partner_key" style={{ maxWidth: 500 }} disabled />
        </Form.Item>

        <Form.Item label="Email" name="email" rules={fields.email.rules}>
          <Input placeholder="email" style={{ maxWidth: 500 }} />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone_number"
          rules={fields.phone_number.rules}
        >
          <Input placeholder="Phone number" style={{ maxWidth: 500 }} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={fields.password.rules}
        >
          <Input.Password
            allowClear
            placeholder="Password"
            style={{ maxWidth: 500 }}
          />
        </Form.Item>

        {(providerInfo?.parent_id && !providerInfo?.is_partner) ||
        !profile?.permission?.edit_reseller ? (
          <></>
        ) : (
          providerInfo && (
            <Form.Item label="Reseller type">
              <div>
                <Select
                  defaultValue={
                    providerInfo?.is_partner ? "partner" : "reseller"
                  }
                  onChange={onChangeResellerType}
                  options={[
                    {
                      value: "reseller",
                      label: "Reseller",
                    },
                    {
                      value: "partner",
                      label: "Partner",
                    },
                  ]}
                  style={{
                    width: 120,
                    marginBottom: "10px",
                  }}
                />
              </div>

              {returnPackageAndCreditsComponents()}
            </Form.Item>
          )
        )}

        <Form.Item style={{ marginTop: "20px" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
              disabled={disabled}
            >
              Update
            </Button>
            {profile?.permission_level && (
              <ConfirmComponent
                confirm={onBlock}
                title={`Are you sure to ${
                  blockReseller ? "Unblock" : "Block"
                } this reseller ?`}
                value={blockReseller ? "Unblock" : "Block"}
              />
            )}
          </div>
        </Form.Item>
      </Form>
      {profile?.permission?.manage_credits && (
        <CreditDrawer
          open={credit}
          onClose={() =>
            setCredit({
              show: false,
              type: "",
            })
          }
          providerId={theProviderId}
          getProviderInfo={getProviderInfo}
        />
      )}
    </>
  );
}
