import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Pagination, Tabs, notification } from "antd";

import Request from "../../Requests";

import { NotificationsPage, BillingHistoryPage, ResellerInfo } from "../index";

import NewBillingHistory from "./NewBillingHistory.jsx";

import { PageComponent } from "../../components";

import AppBrandingsDrawer from "./AppBrandings";
import ResellerDevice from "./ResellerDevicesTab";
import Deactivated from "./Deactivated";
import AvailablePlatforms from "./AvailablePlatforms";
import SubResellers from "./SubResellers";
import DnsInfo from "./DnsInfo";

export default function TheResellerPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [dataTab, setDataTab] = useState([]);

  const [tabKey, setTabKey] = useState(0);
  const [providerInfo, setProviderInfo] = useState(null);
  const [dnsList, setDnsList] = useState([]);

  const theProvider = useRef(null);

  const [partnerProviderOs, setPartnerProviderOs] = useState([]);

  if (location?.search?.split("=")[1]) {
    theProvider.current = location?.search?.split("=")[1];
  }

  useEffect(() => {
    if (tabKey == 0) {
      getProviderInfo();
    }
  }, [tabKey]);

  useEffect(() => {
    if (providerInfo) {
      if (
        providerInfo?.is_partner ||
        (providerInfo?.parent_id && !providerInfo?.is_partner)
      ) {
        data?.splice(1, 0, {
          key: 8,
          label: "Sub resellers",
          children: <SubResellers theProviderId={theProvider?.current} />,
        });
      }

      data?.splice(4, 0, {
        key: 9,
        label: "DNS",
        children: <DnsInfo data={dnsList} />,
      });

      if (partnerProviderOs?.length > 0) {
        data.push({
          key: 7,
          label: "Available platforms",
          children: (
            <AvailablePlatforms
              getData={getDataPartnerProviderOs}
              partnerProviderOs={partnerProviderOs && partnerProviderOs}
            />
          ),
        });
        setDataTab(data);
      }
      setDataTab(data);
    }
  }, [providerInfo, partnerProviderOs?.length, dnsList?.length]);

  const getProviderInfo = () => {
    Request.provider
      .provider_info({ id: theProvider?.current })
      .then((data) => {
        setProviderInfo(data);
        if (data?.is_partner) {
          getDataPartnerProviderOs();
        }
        if (data?.provider_servers?.length > 0) {
          setDnsList(data.provider_servers);
        }
      })
      .catch(({ message }) => {
        // notification.error({ message });
      });
  };

  const getDataPartnerProviderOs = () => {
    Request.partner_provider_os
      .get({ query: JSON.stringify({ pagination: 0 }) })
      .then((data) => {
        const filtered = data?.filter((item) => {
          return item?.provider_id == theProvider?.current;
        });
        if (filtered?.length > 0) {
          setPartnerProviderOs(filtered);
        } else {
          setPartnerProviderOs([]);
        }
      })
      .catch((err) => {});
  };

  const onChange = (key) => {
    setTabKey(key);
  };

  const data = [
    {
      key: 0,
      label: "Reseller info",
      children: (
        <ResellerInfo
          theProviderId={theProvider?.current}
          providerInfo={providerInfo}
          getProviderInfo={getProviderInfo}
        />
      ),
    },

    {
      key: 1,
      label: "Devices",
      children: <ResellerDevice theProviderId={theProvider?.current} />,
    },

    {
      key: 2,
      label: "Deactivated",
      children: <Deactivated theProviderId={theProvider?.current} />,
    },
    {
      key: 3,
      label: "App brandings",
      children: <AppBrandingsDrawer theProviderId={theProvider?.current} />,
    },

    {
      key: 5,
      label: "Credit Transaction",
      children: <BillingHistoryPage theProviderId={theProvider?.current} />,
    },
    {
      key: 6,
      label: "Billing history",
      children: <NewBillingHistory theProviderId={theProvider?.current} />,
    },
  ];

  return (
    <PageComponent>
      <div style={{ display: "flex", alignItems: "baseline", gap: 8 }}>
        <div
          onClick={() => navigate("/dashboard/resellers")}
          className="go-back"
        >
          <ArrowLeftOutlined /> Resellers{" "}
        </div>{" "}
        / <h2>{providerInfo?.brand_name}</h2>
      </div>
      <div className="mt-30">
        <Tabs
          defaultActiveKey={0}
          onChange={onChange}
          items={dataTab?.map((item) => {
            return {
              label: item.label,
              key: item.key,
              children: item.children,
            };
          })}
        />
      </div>
    </PageComponent>
  );
}
