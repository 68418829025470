import { Typography } from "antd";

export default function TableText({
  text = "",
  style = { 
    maxWidth: 170, 
  },
}) {
  return (
    <Typography.Text style={style} ellipsis={{ tooltip: text }}>
      {text}
    </Typography.Text>
  );
}
