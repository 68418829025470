import { Tabs } from "antd";
import ResellerDevice from "./ResellerDevice";
import Deactivated from "./Deactivated";

export default function DevicesTab({ theProviderId }) {
  const items = [
    {
      key: 1,
      label: "All",
      children: <ResellerDevice theProviderId={theProviderId} />,
    },

    {
      key: 2,
      label: "Deactivated",
      children: <Deactivated theProviderId={theProviderId} />,
    },
  ];
  return <Tabs items={items} defaultActiveKey={1} />;
}
