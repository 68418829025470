import { useState, useEffect, useRef } from "react";

import { Table, Tag, Tooltip, Button, Space } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";

import Request from "../../../Requests";

import useColumns from "../../../hooks/useColumns";

import { generateQuery } from "../../../Utils";

import { getColumnSearchProps } from "../../../configs/config";

import {
  TableText,
  TableDateHHMMSS,
  RangePickerFilterDropdown,
  CopyText,
  DeviceInfoDrawer,
} from "../../../components";

export default function ResellerDevice({ theProviderId }) {
  const fieldsType = {
    device_key: "STRING",
    app_name: "STRING",
    country: "STRING",
    ip_address: "STRING",
    playlist: "STRING",
    expired_date: "INTEGER",
    free_trial_expired: "INTEGER",
    auto_renew: "INTEGER",
    is_active: "FILTER",
    createdAt: "DATE",
    updatedAt: "DATE",
    app_version: "STRING",
    model: "STRING",
    browser_version: "STRING",
  };

  const defaultTableState = {
    filteredInfo: {},
    sortedInfo: {},
    page: 1,
    limit: 10,
  };

  const searchInputRef = useRef(null);

  const [openDeviceInfo, setOpenDeviceInfo] = useState({
    open: false,
    deviceKey: null,
  });

  const [columns, setColumns] = useState([
    {
      title: "#",
      fixed: "left",
      key: "row__index",
      align: "center",
      render: (text, record, index) => record.index,
    },
    {
      title: "Device key",
      dataIndex: "device_key",
      key: "device_key",
      align: "center",
      fixed: "left",
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "App name",
      dataIndex: "app_name",
      key: "app_name",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      render: (text, record, index) =>
        record.is_active ? (
          <Tag color="green">Active</Tag>
        ) : !record.is_active && record.is_trial ? (
          <Tag color="gold">Trial</Tag>
        ) : (
          <Tag color="red">Deactive</Tag>
        ),
      filters: [
        {
          text: "Trial",
          value: "is_trial",
        },
        {
          text: "Active",
          value: true,
        },
        {
          text: "Deactive",
          value: false,
        },
      ],
      filterMultiple: false,
    },
    {
      title: "Device os",
      dataIndex: "device_os",
      key: "device_os",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
    },
    {
      title: "Playlist",
      dataIndex: "playlist",
      key: "playlist",
      align: "center",
      width: 170,
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) => {
        const playlistStatus = record?.playlist_status?.toLowerCase();
        if (!record?.playlist) {
          return "N/A";
        } else if (playlistStatus === "wrong" || playlistStatus === "expired") {
          return (
            <>
              <Space>
                <Tooltip title={checkPlaylistForError(playlistStatus)}>
                  <Button
                    icon={<InfoCircleOutlined style={{ color: "#cf1222" }} />}
                    type="link"
                  />
                </Tooltip>

                <CopyText
                  text={
                    <TableText
                      text={record?.playlist}
                      style={{ color: "#cf1222" }}
                    />
                  }
                  style={{ color: "#cf1222" }}
                />
              </Space>
            </>
          );
        } else {
          return (
            <Space>
              <CopyText text={<TableText text={record?.playlist} />} />
            </Space>
          );
        }
      },
    },
    {
      title: "IP address",
      dataIndex: "ip_address",
      key: "ip_address",
      align: "center",
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (text, record, index) =>
        record.country || record.isoCode ? (
          <TableText text={`${record.country} (${record.isoCode})`} />
        ) : (
          "N/A"
        ),
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Auto renew",
      dataIndex: "auto_renew",
      key: "auto_renew",
      align: "center",
      render: (text, record, index) => (
        <TableText
          text={
            text ? (
              <span>
                <CheckOutlined style={{ color: "#2ec4b6" }} />
              </span>
            ) : (
              <span>
                <CloseOutlined style={{ color: "#e63946" }} />
              </span>
            )
          }
        />
      ),
    },
    {
      title: "Expired date",
      dataIndex: "expired_date",
      key: "expired_date",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
    },
    {
      title: "Free trial expired",
      dataIndex: "free_trial_expired",
      key: "free_trial_expired",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
    },

    {
      title: "Updated date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (text, record, index) => <TableDateHHMMSS date={text} />,
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },

    {
      title: "Created date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) => <TableDateHHMMSS date={text} />,
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },
    {
      title: "App version",
      dataIndex: "app_version",
      key: "app_version",
      align: "center",
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) =>
        record?.app_version ? record?.app_version : "N/A",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      align: "center",
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) => (record?.model ? record?.model : "N/A"),
    },

    {
      title: "Browser version",
      dataIndex: "browser_version",
      key: "browser_version",
      align: "center",
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) =>
        record?.browser_version ? record?.browser_version : "N/A",
    },
    {
      title: "",
      key: "row__actions",
      fixed: "right",
      width: 60,
      align: "center",
      render: (text, record, index) => (
        <div
          onClick={() =>
            setOpenDeviceInfo({ open: true, deviceKey: record?.device_key })
          }
          className="on-row"
        >
          <RightOutlined />
        </div>
      ),
    },
  ]);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const [tableState, setTableState] = useState(defaultTableState);

  const [total, setTotal] = useState(0);

  const [onChangeColumns] = useColumns(columns, setColumns, "ResellerDevices");

  const getData = () => {
    let query = generateQuery(tableState, fieldsType);

    if (theProviderId) {
      let providerQuery = JSON.parse(query.query);

      if (
        providerQuery?.filter &&
        providerQuery?.filter?.is_active[0] == "is_trial"
      ) {
        providerQuery = {
          ...providerQuery,
          filter: {
            provider_id: theProviderId,
            is_trial: [true],
          },
        };
      } else if (
        providerQuery?.filter &&
        providerQuery?.filter?.is_active[0] == false
      ) {
        providerQuery = {
          ...providerQuery,
          filter: {
            provider_id: theProviderId,
            is_trial: [false],
            is_active: [false],
          },
        };
      } else if (
        providerQuery?.filter &&
        providerQuery?.filter?.is_active[0] == true
      ) {
        providerQuery = {
          ...providerQuery,
          filter: {
            provider_id: theProviderId,
            is_active: [true],
          },
        };
      } else {
        providerQuery = {
          ...providerQuery,
          filter: {
            provider_id: theProviderId,
          },
        };
      }

      query.query = JSON.stringify(providerQuery);
    }

    setLoading(true);

    Request.resller_devices
      .get(query, theProviderId)
      .then((data) => {
        if (!data.rows.length && tableState.page > 1) {
          return setTableState({ ...tableState, page: tableState.page - 1 });
        }
        setLoading(false);
        setData(
          data.rows.map((item, index) => ({
            index: (tableState.page - 1) * tableState.limit + index + 1,
            ...item,
          }))
        );
        setTotal(data.total);
      })
      .catch(({ message }) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let _columns = [...columns];
    let { filteredInfo, sortedInfo } = tableState;

    _columns.map((column) => {
      column.filteredValue = filteredInfo[column.key] || null;
      column.sortOrder = sortedInfo.field === column.key && sortedInfo.order;
    });

    setColumns(_columns);

    const timeout = setTimeout(() => {
      getData();
    }, 500);
    return () => clearTimeout(timeout);
  }, [tableState, theProviderId]);

  const tableOnChange = (pagination, filters, sorter) => {
    let { order, field } = sorter;

    setTableState({
      filteredInfo: filters,
      sortedInfo: { order, field },
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const checkPlaylistForError = (playlistStatus) => {
    switch (playlistStatus) {
      case "wrong":
        return "Please check the playlist as it is incorrectly filled in.";
      case "expired":
        return "Please check this playlist as its expiration date has expired.";
      default:
    }
  };

  return (
    <>
      <div className="tb-header" style={{ margin: "10px 0px 20px 0px" }}>
        <div style={{ fontSize: "23px" }}>
          <span style={{ fontSize: "15px", marginRight: "10px" }}>Total:</span>
          {""}
          <span>{total}</span>
        </div>
      </div>
      <Table
        loading={loading}
        rowKey="id"
        columns={columns.filter((column) => column.visible)}
        dataSource={data}
        scroll={{
          x: "max-content",
          y: null,
        }}
        bordered={true}
        onChange={tableOnChange}
        size="small"
        pagination={{
          position: ["bottomCenter"],
          current: tableState.page,
          total: total,
          pageSize: tableState.limit,
          showSizeChanger: true,
        }}
      />
      <DeviceInfoDrawer
        deviceKey={openDeviceInfo?.deviceKey}
        open={openDeviceInfo?.open}
        onClose={() => setOpenDeviceInfo({ open: false, deviceKey: null })}
      />
    </>
  );
}
