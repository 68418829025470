import React, { useState, useEffect } from "react";

export default function PageComponent({ children }) {
  const [blured, setBlured] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setBlured(false);
    }, 500);
  }, []);
  return <div className={`${blured ? "page-blured" : ""}`}>{children}</div>;
}
