import { useState, useEffect } from "react";
import Request from "../Requests";
import countries from "../configs/countries";

export function useCountries() {
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    const options = countries.map((item) => {
      return {
        label: item.name,
        value: item.name,
      };
    });

    setCountriesList(options);
  }, []);
  return countriesList;
}

export function usePartnerPackages(added) {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    Request.partner_packages
      .get({ query: JSON.stringify({ page: 1, limit: 1000 }) })
      .then((data) => {
        const filteredPackages = data.rows.filter((item) => {
          return !item.archived;
        });

        const options = filteredPackages.map((item) => {
          return {
            label: `${item.name} ( Activations ${item.device_count} ) $${item.price} `,
            value: item.id,
          };
        });

        setPackages(options);
      });
  }, [added]);
  return packages;
}

export function useAdminsOptions() {
  const [admins, setAdmins] = useState([]);
  useEffect(() => {
    Request.admins
      .get({ query: JSON.stringify({ pagination: 0 }) })
      .then((data) => {
        const list = data.map((item) => ({
          label: item.name ? item.name : "N/A" + " " + `(${item.email})`,
          value: item.id,
        }));
        setAdmins(list);
      });
  }, []);
  return admins;
}

export function useProviderOptions() {
  const [provider, setProvider] = useState([]);

  useEffect(() => {
    Request.provider.get(
      { query: JSON.stringify({ pagination: 0 }) },
      (data) => {
        const list = data.map((item) => ({
          label: item.name,
          value: item.id,
        }));

        setProvider(list);
      }
    );
  }, []);

  return provider;
}

export function useBrandsOptions() {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    Request.app_brandings
      .get({ query: JSON.stringify({ pagination: 0 }) })
      .then((data) => {
        const list = data.map((item) => ({
          text: item?.provider?.brand_name,
          value: item?.provider?.brand_name,
        }));
        setBrands(list);
      });
  }, []);

  return brands;
}

export function useProviderEmailsOptions() {
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    Request.app_brandings
      .get({ query: JSON.stringify({ pagination: 0 }) })
      .then((data) => {
        const list = data.map((item) => ({
          text: item?.provider?.email,
          value: item?.provider?.email,
        }));
        setEmails(list);
      });
  }, []);

  return emails;
}

export function useCountrysOptions() {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    Request.dashboard.provider_country_chart({}).then((data) => {
      const list = data.map((item) => ({
        text: item.country,
        value: item.country,
      }));
      setCountries(list);
    });
  }, []);

  return countries;
}

export function usePlatformsOptions() {
  const [platforms, setPlatforms] = useState([]);
  const isReleasedArr = [];
  useEffect(() => {
    Request.platforms.get({}).then((data) => {
      data?.rows?.map((el) => {
        if (el.is_released) {
          isReleasedArr.push(el);
        }
      });
      const list = isReleasedArr?.map((item) => ({
        text: item.device_os,
        value: item.device_os,
      }));
      setPlatforms(list);
    });
  }, []);

  return platforms;
}
