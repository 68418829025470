import React, { useState, useEffect } from "react";
import { Pie } from "@ant-design/plots";
import { Card, Empty } from "antd";
import Request from "../../../Requests";

export default function ByStatusDevices() {
    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        Request.resller_devices
            .by_status({})
            .then((data) => {
                setDataList(data);
            })
            .catch(({ err }) => {
                console.log(err);
            });
    };
    const config = {
        appendPadding: 10,
        data: dataList && dataList,
        angleField: "value",
        colorField: "type",
        radius: 0.9,
        label: false,

        interactions: [
            {
                type: "element-active",
            },
        ],
        style: {
            minHeight: 250,
        },
    };
    return (
        <Card title="Devices by Status">
            {dataList.length > 0 ? <Pie {...config} /> : <Empty />}
        </Card>
    );
}
