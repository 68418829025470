import { Drawer, Tabs, Form } from "antd";
import { useState, useEffect } from "react";

import Request from "../../Requests";

import ResellerInfo from "./components/ResellerInfo";
import SubResellers from "./components/SubResellers";
import DnsInfo from "./components/DnsInfo";
import AvailablePlatforms from "./components/AvailablePlatforms";
import AppBrandingsDrawer from "./components/AppBrandingsDrawer";
import CreditTransaction from "./components/CreditTransaction";
import BillingHistory from "./components/BillingHistory";
import DevicesTab from "./components/DevicesTab";

export default function ResellerInfoDrawer({ open, onClose, resellerId }) {
  const [dataTab, setDataTab] = useState([]);

  const [tabKey, setTabKey] = useState(0);
  const [providerInfo, setProviderInfo] = useState(null);
  const [dnsList, setDnsList] = useState([]);

  const [partnerProviderOs, setPartnerProviderOs] = useState([]);

  const [formResellerInfo] = Form.useForm();

  useEffect(() => {
    console.log(open);

    if (open) {
      getProviderInfo();
    } else {
      formResellerInfo.resetFields();
      setTabKey(0);
      setProviderInfo(null);
      setDnsList([]);
      setPartnerProviderOs([]);
    }
  }, [open, resellerId, tabKey]);

  useEffect(() => {
    if (providerInfo) {
      if (
        providerInfo?.is_partner ||
        (providerInfo?.parent_id && !providerInfo?.is_partner)
      ) {
        data?.splice(1, 0, {
          key: 8,
          label: "Sub resellers",
          children: <SubResellers theProviderId={resellerId} />,
        });
      }

      data?.splice(4, 0, {
        key: 9,
        label: "DNS",
        children: <DnsInfo data={dnsList} />,
      });

      if (partnerProviderOs?.length > 0) {
        data.push({
          key: 7,
          label: "Available platforms",
          children: (
            <AvailablePlatforms
              getData={getDataPartnerProviderOs}
              partnerProviderOs={partnerProviderOs && partnerProviderOs}
            />
          ),
        });
        setDataTab(data);
      }
      setDataTab(data);
    }
  }, [providerInfo, partnerProviderOs?.length, dnsList?.length]);

  const onChange = (key) => {
    setTabKey(Number(key));
  };

  const onTabClick = (key) => {
    setTabKey(Number(key));
  };

  const getProviderInfo = () => {
    Request.provider
      .provider_info({ id: resellerId })
      .then((data) => {
        setProviderInfo(data);
        if (data?.is_partner) {
          getDataPartnerProviderOs();
        }
        if (data?.provider_servers?.length > 0) {
          setDnsList(data.provider_servers);
        }
      })
      .catch(({ message }) => {});
  };

  const getDataPartnerProviderOs = () => {
    Request.partner_provider_os
      .get({ query: JSON.stringify({ pagination: 0 }) })
      .then((data) => {
        const filtered = data?.filter((item) => {
          return item?.provider_id == resellerId;
        });
        if (filtered?.length > 0) {
          setPartnerProviderOs(filtered);
        } else {
          setPartnerProviderOs([]);
        }
      })
      .catch((err) => {});
  };

  const data = [
    {
      key: 0,
      label: "Info",
      children: (
        <ResellerInfo
          theProviderId={resellerId}
          providerInfo={providerInfo}
          getProviderInfo={getProviderInfo}
          form={formResellerInfo}
        />
      ),
    },

    {
      key: 1,
      label: "Devices",
      children: <DevicesTab theProviderId={resellerId} />,
    },

    {
      key: 3,
      label: "App brandings",
      children: <AppBrandingsDrawer theProviderId={resellerId} />,
    },

    {
      key: 5,
      label: "Credit Transaction",
      children: <CreditTransaction theProviderId={resellerId} />,
    },
    {
      key: 6,
      label: "Billing history",
      children: <BillingHistory theProviderId={resellerId} />,
    },
  ];
  return (
    <Drawer
      title={`Reseller Info: ID_ ${resellerId} `}
      open={open}
      onClose={onClose}
      size="large"
      className="info-drawer"
    >
      <Tabs
        activeKey={tabKey}
        animated={true}
        type="card"
        onChange={onChange}
        onTabClick={onTabClick}
        items={dataTab?.map((item) => {
          return {
            label: item.label,
            key: item.key,
            children: item.children,
          };
        })}
      />
    </Drawer>
  );
}
