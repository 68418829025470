import { useEffect, useState } from "react";

import Request from "../../Requests";

import { Drawer, Form, Button, notification, Input, Select } from "antd";
import { FileChooser } from "../../components";

const languages = [
  { iso_code: "en", name: "English" },
  { iso_code: "pt", name: "Portuguese" },
  { iso_code: "sq", name: "Albanian" },
];

export default function NewsDrawer({ visible, onClose, selectedRow }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      if (selectedRow) {
        const { title, description, image } = selectedRow;

        form.setFieldsValue({
          title,
          description,
          image: image,
        });

        filterTranslations("en");
      } else {
        form.resetFields();
      }
    }
  }, [visible, selectedRow]);

  const onFinish = (values) => {
    let method = "post";
    const { title, description, iso_code, image } = values;

    setLoading(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("iso_code", iso_code);

    if (typeof image === "object") {
      formData.append("image", image[0]?.originFileObj);
    }

    if (selectedRow) {
      method = "put";
      formData.append("id", selectedRow.id);
    }

    Request.news[method](formData)
      .then(() => {
        setLoading(false);
        hideDrawer(true);
        const languageName = languages.find(
          (item) => item.iso_code === iso_code
        )?.name;
        notification.success({
          message: "News for " + languageName + " saved successfully",
        });
      })
      .catch(({ message }) => {
        setLoading(false);
        notification.error({ message });
      });
  };

  const hideDrawer = (changed) => {
    onClose(changed);
    form.resetFields();
  };

  const filterTranslations = (iso_code) => {
    if (selectedRow?.news_translations) {
      const finded = selectedRow.news_translations.find(
        (item) => item.iso_code === iso_code
      );

      if (finded) {
        form.setFieldsValue({
          title: finded.title,
          description: finded.description,
        });
      } else {
        form.setFieldsValue({
          title: "",
          description: "",
        });
      }
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.iso_code) {
      filterTranslations(changedValues.iso_code);
    }
  };

  return (
    <Drawer
      title={selectedRow ? "Update" : "Add"}
      placement="right"
      onClose={() => hideDrawer(false)}
      open={visible}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={onValuesChange}
        initialValues={{
          iso_code: "en",
        }}
      >
        <Form.Item label="Image" name="image">
          <FileChooser style={{ width: 400 }} />
        </Form.Item>

        <Form.Item label="Language" name="iso_code">
          <Select placeholder="Status" defaultValue="en">
            <Select.Option value="en">🇺🇸 English</Select.Option>
            <Select.Option value="pt">🇧🇷 Portuguese</Select.Option>
            <Select.Option value="sq">🇦🇱 Albanian</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Title" name="title">
          <Input placeholder="Title" />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea
            placeholder="Description"
            rows={10}
            style={{ resize: "none" }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {selectedRow ? "Update" : "Add"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
