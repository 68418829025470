import {
  Drawer,
  Form,
  Input,
  InputNumber,
  Button,
  notification,
  Select,
  Space,
  Radio,
  Tooltip,
  message,
} from "antd";
import {
  PlusOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  TransactionOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useCountries } from "../../hooks/useSelctOptions";
import Request from "../../Requests";
import PartnerPackagesDrawer from "../partner-packages/PartnerPackagesDrawer";

export default function NewResellerDrawer({ open, onClose, getData }) {
  const countriesOptions = useCountries();

  const [partnerPackagesList, setPartnerPackagesList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [isValidBrandName, setIsValidBrandName] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const [addNewPackage, setAddNewPackage] = useState(false);
  const [addNewDns, setAddNewDns] = useState(false);

  const [password, setPassword] = useState("");

  const [form] = Form.useForm();

  let brandNameTimeout;
  let emailTimeout;

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setErrorMessage(null);
      setLoading(false);
      setIsValidBrandName(false);
      setIsValidEmail(false);
    }
    getOptionsPartnerPackages();
  }, [open]);

  const generatePassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    let generatedPassword = "";
    for (let i = 0; i < length; i++) {
      generatedPassword += charset.charAt(
        Math.floor(Math.random() * charset.length)
      );
    }
    form.setFields([
      { name: "password", value: generatedPassword, errors: [] },
    ]);

    setPassword(generatedPassword);
  };

  const copyPassword = () => {
    if (password) {
      navigator.clipboard
        .writeText(password)
        .then(() => {
          message.success("Password copied to clipboard!");
        })
        .catch((err) => {
          message.error("Failed to copy password!");
        });
    } else {
      message.warning("No password to copy!");
    }
  };

  const getOptionsPartnerPackages = () => {
    Request.partner_packages
      .get({ query: JSON.stringify({ page: 1, limit: 1000 }) })
      .then((data) => {
        if (data?.rows) {
          const filteredPackages = data.rows.filter((item) => !item.archived);

          const options = filteredPackages.map((item) => ({
            label: `${item.name} ( Activations ${item.device_count} ) $${item.price} `,
            value: item.id,
          }));

          form.setFields([
            {
              name: "partner_package_id",
              value: options[options?.length - 1]?.value,
            },
          ]);

          setPartnerPackagesList(options);
        }
      })
      .catch((error) => {
        console.error("Error fetching partner packages:", error);
      });
  };

  const getValidBrandName = (brand) => {
    const hasSymbolsOrSpaces = /[^a-zA-Z0-9]/.test(brand);

    if (hasSymbolsOrSpaces) {
      form.setFields([
        {
          name: "brand_name",
          errors: ["The brand name must not contain any symbols or spaces."],
        },
      ]);
    } else {
      Request.provider
        .validate_brand_name({ brand_name: brand })
        .then(() => {
          setIsValidBrandName(false);
          form.setFields([{ name: "brand_name", errors: [] }]);
        })
        .catch((err) => {
          if (err) {
            setIsValidBrandName(true);
            form.setFields([
              {
                name: "brand_name",
                errors: [err.message],
              },
            ]);
          }
          setLoading(false);
        });
    }
  };

  const getValidEmail = (email) => {
    Request.provider
      .validate_email({ email: email })
      .then(() => {
        setIsValidEmail(false);
        form.setFields([{ name: "email", errors: [] }]);
      })
      .catch((err) => {
        if (err) {
          setIsValidEmail(true);
          form.setFields([
            {
              name: "email",
              errors: [err.message],
            },
          ]);
        }
        setLoading(false);
      });
  };

  const onValuesChange = (changedValues, allValues) => {
    let key = Object.keys(changedValues)[0];
    switch (key) {
      case "brand_name":
        if (brandNameTimeout) {
          clearTimeout(brandNameTimeout);
        }
        brandNameTimeout = setTimeout(() => {
          getValidBrandName(changedValues.brand_name);
        }, 1000);

        break;
      case "email":
        if (emailTimeout) {
          clearTimeout(emailTimeout);
        }
        emailTimeout = setTimeout(() => {
          getValidEmail(changedValues.email);
        }, 1000);
        break;
      case "password":
        form.setFields([
          { name: "password", value: changedValues.password, errors: [] },
        ]);
        setPassword(changedValues.password);
        break;
    }
  };

  const onFinish = (values) => {
    if (!open || !values) return;

    setLoading(true);
    setErrorMessage(null);

    const formattedValues = {
      ...values,
      phone_number: String(values.phone_number),
    };

    if (values.reseller_type == "is_reseller") {
      formattedValues.is_partner = false;
      formattedValues.is_seller = false;
    }
    if (values.reseller_type == "is_partner") {
      formattedValues.is_partner = true;
      formattedValues.is_seller = false;
    }
    if (values.reseller_type == "is_seller") {
      formattedValues.is_partner = true;
      formattedValues.is_seller = true;
    }

    delete formattedValues.reseller_type;

    if (values.brand_name && values.email) {
      Request.provider
        .post(formattedValues)
        .then((res) => {
          setLoading(false);
          notification.success({ description: res });
          getData();
          onClose();
        })
        .catch((err) => {
          setLoading(false);
          setErrorMessage(err.message);
        });
    }
  };

  return (
    <>
      <Drawer
        title="Register New Reseller"
        size="large"
        open={open}
        onClose={onClose}
        className="register-new-reseller"
      >
        <Form
          name="register-reseller"
          layout="vertical"
          form={form}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          initialValues={{
            reseller_type: "is_reseller",
          }}
        >
          <div className="grid-new-register">
            <Form.Item
              label="Brand name"
              name="brand_name"
              rules={[
                {
                  required: true,
                  message: "Please input your brand name",
                },
              ]}
            >
              <Input placeholder="Brand name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              autoComplete="off"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                },
                {
                  type: "email",
                  message: "Please input valid email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </div>

          <div className="grid-new-register">
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: false,
                  message: "",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              label="Surname"
              name="surname"
              rules={[
                {
                  required: false,
                  message: "",
                },
              ]}
            >
              <Input placeholder="Surname" />
            </Form.Item>
          </div>

          <div className="grid-new-register">
            <Form.Item
              label="Phone"
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number",
                },
              ]}
            >
              <InputNumber
                placeholder="Phone"
                controls={false}
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Space direction="horizontal" className="generate-pass">
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password",
                  },

                  {
                    min: 8,
                    message: "Password must be minimum 8 characters",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  autoComplete="new-password"
                  visibilityToggle={{ visible: true }}
                  addonBefore={
                    <Button
                      onClick={copyPassword}
                      icon={<CopyOutlined />}
                      type="link"
                      style={{ height: "26px" }}
                      disabled={!password}
                    />
                  }
                />
              </Form.Item>
              <Button
                type="link"
                onClick={generatePassword}
                style={{ fontSize: "22px", padding: 0 }}
              >
                <Tooltip title="Generate password">
                  <TransactionOutlined />
                </Tooltip>
              </Button>
            </Space>
          </div>

          <div className="grid-new-register reseller-type">
            <Form.Item name="reseller_type" label="Reseller types">
              <Radio.Group value="is_reseller">
                <Radio value="is_reseller">Reseller</Radio>
                <Radio value="is_partner">Partner</Radio>
                <Radio value="is_seller">Seller</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {() => {
                const { reseller_type } = form.getFieldsValue();
                return reseller_type == "is_reseller" ? (
                  <Form.Item name="credit" label="Credit">
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Add credit"
                      controls={false}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <Space
                      direction="horizontal"
                      className="add_reseller_partner_package"
                    >
                      <Form.Item
                        name="partner_package_id"
                        label="Packages"
                        rules={[
                          {
                            required: reseller_type !== "is_reseller",
                            message: "Please input your package",
                          },
                        ]}
                      >
                        <Select
                          options={partnerPackagesList}
                          showSearch
                          placeholder="Search by package"
                          optionFilterProp="label"
                          onChange={() => {}}
                          onSearch={() => {}}
                        />
                      </Form.Item>
                      <Button
                        onClick={() => setAddNewPackage(true)}
                        icon={<PlusOutlined />}
                        type="primary"
                        size="small"
                        style={{ marginTop: "6px" }}
                      />
                    </Space>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </div>

          <div className="grid-new-register">
            <Form.Item label="Country" name="country">
              <Select
                showSearch
                allowClear
                placeholder="Search by country"
                optionFilterProp="label"
                onChange={() => {}}
                onSearch={() => {}}
                options={countriesOptions}
              />
            </Form.Item>

            <Form.Item label="Create default DNS">
              <Button
                icon={addNewDns ? <CaretDownOutlined /> : <CaretUpOutlined />}
                type="default"
                style={{ marginBottom: "10px", width: "100%" }}
                onClick={() => setAddNewDns((prev) => !prev)}
              >
                {" "}
                Add DNS
              </Button>
            </Form.Item>
          </div>

          <div className="grid-new-register">
            {addNewDns && (
              <>
                <Form.Item
                  name="server_name"
                  label="Host name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your host name",
                    },
                  ]}
                >
                  <Input placeholder="Enter name" style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  name="server_host"
                  label={
                    <span>
                      Host URL
                      <span style={{ color: "grey" }}>
                        (http://example.com)
                      </span>
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input Host",
                    },
                    {
                      type: "url",
                      message: "That's not a valid host",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter host"
                    style={{ width: "100%" }}
                    type="url"
                  />
                </Form.Item>
              </>
            )}
          </div>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
              disabled={isValidBrandName || isValidEmail}
            >
              Save
            </Button>
          </Form.Item>

          {errorMessage && <p style={{ color: "#ea4a50" }}>{errorMessage}</p>}
        </Form>
      </Drawer>
      <PartnerPackagesDrawer
        visible={addNewPackage}
        onClose={() => setAddNewPackage(false)}
        isGetOptions={true}
        getOptionsPartnerPackages={getOptionsPartnerPackages}
      />
    </>
  );
}
