import { useState, useEffect, useRef } from "react";

import { Table, Tag, Button } from "antd";

import Request from "../../Requests";

import { generateQuery } from "../../Utils";

import useColumns from "../../hooks/useColumns";

import {
  TableText,
  TableDateHHMMSS,
  RangePickerFilterDropdown,
  CopyText,
  ResellerInfoDrawer,
} from "../../components";

import { getColumnSearchProps } from "../../configs/config";

export default function DeviceStateChanges({ deviceKey }) {
  const fieldsType = {
    provider_id: "INTEGER",
    brand_name: "STRING",
    deactivated: "BOOLEAN",
    createdAt: "DATE",
  };

  const defaultTableState = {
    filteredInfo: {},
    sortedInfo: {},
    page: 1,
    limit: 20,
  };
  const searchInputRef = useRef(null);

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [tableState, setTableState] = useState(defaultTableState);

  const [total, setTotal] = useState(0);

  const [openResellerInfo, setOpenResellerInfo] = useState({
    open: false,
    resellerId: null,
  });

  const [columns, setColumns] = useState([
    {
      title: "#",
      fixed: "left",
      key: "row__index",
      align: "center",
      render: (text, record, index) => record.index,
    },

    {
      title: "Status",
      dataIndex: "deactivated",
      key: "deactivated",
      align: "center",
      width: 230,
      render: (text, record, index) => (
        <Tag color={record.deactivated ? "red" : "green"}>
          {record.deactivated
            ? `${(
                record?.by?.charAt(0)?.toUpperCase() +
                record?.by?.slice(1)?.toLowerCase()
              )?.replaceAll("_", " ")} deactivated this device`
            : `${(
                record?.by?.charAt(0)?.toUpperCase() +
                record?.by?.slice(1)?.toLowerCase()
              )?.replaceAll("_", " ")} activated this device`}
        </Tag>
      ),
      filters: [
        { text: "Deactivated", value: true },
        { text: "Activated", value: false },
      ],
    },

    {
      title: "Provider ID",
      dataIndex: "provider_id",
      key: "provider_id",
      align: "center",
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) =>
        record.provider_id ? (
          <Button
            type="ghost"
            onClick={() =>
              setOpenResellerInfo({
                open: true,
                resellerId: record?.provider_id,
              })
            }
          >
            {text}
          </Button>
        ) : (
          "N/A"
        ),
    },
    {
      title: "Brand name",
      dataIndex: "brand_name",
      key: "brand_name",
      align: "center",
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "IP address",
      dataIndex: "ip_address",
      key: "ip_address",
      align: "center",
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
    },
    {
      title: "Created date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },
  ]);

  const [onChangeColumns] = useColumns(
    columns,
    setColumns,
    "device_state_changes"
  );

  const getData = () => {
    let query = generateQuery(tableState, fieldsType);

    if (deviceKey) {
      let providerQuery = JSON.parse(query.query);
      providerQuery = {
        ...providerQuery,
        filter: { device_key: deviceKey },
      };
      query.query = JSON.stringify(providerQuery);
    }

    setLoading(true);

    Request.device_state_changes(query)
      .then((data) => {
        if (!data.rows.length && tableState.page > 1) {
          return setTableState({ ...tableState, page: tableState.page - 1 });
        }

        setLoading(false);
        setData(
          data.rows.map((item, index) => ({
            index: (tableState.page - 1) * tableState.limit + index + 1,
            ...item,
          }))
        );
        setTotal(data.total || data.count);
      })
      .catch(({ message }) => {
        setLoading(false);
      });
  };

  const tableOnChange = (pagination, filters, sorter) => {
    let { order, field } = sorter;

    setTableState({
      filteredInfo: filters,
      sortedInfo: { order, field },
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  useEffect(() => {
    let _columns = [...columns];
    let { filteredInfo, sortedInfo } = tableState;

    _columns.map((column) => {
      column.filteredValue = filteredInfo[column.key] || null;
      column.sortOrder = sortedInfo.field === column.key && sortedInfo.order;
    });

    setColumns(_columns);

    const timeout = setTimeout(() => {
      getData();
    }, 500);
    return () => clearTimeout(timeout);
  }, [tableState]);

  return (
    <>
      <Table
        loading={loading}
        rowKey="id"
        columns={columns.filter((column) => column.visible)}
        dataSource={data}
        scroll={{
          x: "max-content",
          y: null,
        }}
        onChange={tableOnChange}
        size="small"
        pagination={{
          position: ["bottomCenter"],
          current: tableState.page,
          total: total,
          pageSize: tableState.limit,
          showSizeChanger: true,
        }}
        bordered
      />
      <ResellerInfoDrawer
        resellerId={openResellerInfo?.resellerId}
        open={openResellerInfo.open}
        onClose={() => setOpenResellerInfo({ open: false, resellerId: null })}
      />
    </>
  );
}
