import { useState, useEffect } from "react";
import { Table } from "antd";
import { TableText, TableCSV, TableDateHHMMSS } from "../../../components";
import Request from "../../../Requests";

import moneyPng from "../../../assets/money.png";

export default function NewBillingHistoryPage({ theProviderId }) {
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [dataForExport, setDataForExport] = useState([]);
  const [billingStatistics, setBillingStatistics] = useState([]);

  const columns = [
    {
      title: "#",
      fixed: "left",
      key: "row__index",
      render: (text, record, index) => index + 1,
    },
    {
      key: "payment_method",
      title: "Method",
      dataIndex: "payment_method",
      render: (text, record, index) => (
        <TableText text={text ? text.replace(/_/g, " ") : "N/A"} />
      ),
      searched: true,
    },
    {
      key: "credit",
      title: "Credit",
      dataIndex: "credit",
      render: (text, record, index) =>
        text ? <TableText text={text} /> : "N/A",
      sorter: (a, b) => a.credit - b.credit,
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      render: (text, record, index) =>
        text ? <TableText text={text} /> : "N/A",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      key: "createdAt",
      title: "Date",
      dataIndex: "createdAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
    },
  ];

  const getData = () => {
    Request.billing_amount
      .get({ provider_id: theProviderId })
      .then((data) => {
        const formattedData = Object.keys(data).map((key) => ({
          payment_method: key,
          sum: data[key],
        }));

        setBillingStatistics(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching billing statistics:", error.message);
      });
  };

  const getTableData = () => {
    const query = {
      provider_id: theProviderId,
    };

    Request.billing_history_admin
      .get(query)
      .then((data) => {
        setTableLoading(false);
        setTableData(data);
      })
      .catch((error) => {
        setTableLoading(false);
        console.error("Error fetching table data:", error.message);
      });
  };

  useEffect(() => {
    if (theProviderId) {
      getData();
      getTableData();
    }
  }, [theProviderId]);

  const onExport = (selected) => {
    if (selected === "allData") {
      Request.billing_history_admin
        .get({ provider_id: theProviderId })
        .then((data) => {
          setDataForExport(data);
        })
        .catch(({ message }) => {
          console.error("Error exporting all data:", message);
        });
    } else if (selected === "data") {
      setDataForExport(tableData);
    }
  };

  return (
    <div>
      <div>
        <h3>Total counts:</h3>
        <div className="statistics-list">
          {billingStatistics.map((el, index) => (
            <div className="statistic-component" key={index}>
              <div className="info">
                <p className="title">{el.payment_method.replace(/_/g, " ")}</p>
                <p className="value">{el.sum}</p>
              </div>
              <img src={moneyPng} alt="money" />
            </div>
          ))}
        </div>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 8,
            marginBottom: 15,
          }}
        >
          <div>
            <TableCSV dataForExport={dataForExport} onExport={onExport} />
          </div>
        </div>
        <Table
          loading={tableLoading}
          rowKey="id"
          columns={columns}
          dataSource={tableData}
          bordered
        />
      </div>
    </div>
  );
}
