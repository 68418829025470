import { Button, Popconfirm } from "antd";

export default function ConfirmComponent({
  value = "Delete",
  description = "",
  title = "Are you sure to delete this item?",
  cancel = () => {},
  confirm = () => {},
  type,
}) {
  return (
    <Popconfirm
      title={title}
      description={description}
      onConfirm={confirm}
      onCancel={cancel}
      okText="Yes"
      cancelText="No"
    >
      <Button type={type}>{value}</Button>
    </Popconfirm>
  );
}
