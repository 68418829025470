import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Popover, Table, notification, Button, Tag, Select } from "antd";
import { RightOutlined } from "@ant-design/icons";

import { getColumnSearchProps, getColumnDateProps } from "../../configs/config";

import errorImg from "../../assets/error-image.png";

import {
  TableSettingsButton,
  TableResetButton,
  TableText,
  TableCSV,
  TableBoolean,
  TableDateHHMMSS,
  TitlePage,
  RangePickerFilterDropdown,
  CopyText,
  TableAddButton,
  ResellerInfoDrawer,
} from "../../components";

import Request from "../../Requests";

import { generateQuery } from "../../Utils";

import useColumns from "../../hooks/useColumns";
import useHistorySettings from "../../hooks/useHistorySettings";
import { useAdminsOptions } from "../../hooks/useSelctOptions";
import NewResellerDrawer from "./NewResellerDrawer";

export default function ResellerPage() {
  const fieldsType = {
    id: "INTEGER",
    account_type: "CUSTOM_FILTER",
    brand_name: "STRING",
    name: "STRING",
    surname: "STRING",
    email: "STRING",
    phone_number: "NUMBER",
    parent_id: "STRING",
    partner_key: "STRING",
    server_host: "STRING",
    credit: "INTEGER",
    app_activation_type: "ENUM",
    device_count: "NUMBER",
    app_trial_days: "INTEGER",
    app_auto_renew: "BOOLEAN",
    app_login: "BOOLEAN",
    is_online: "BOOLEAN",
    createdAt: "DATE",
    online_date: "DATE",
    blocked: "BOOLEAN",
    updatedAt: "DATE",
  };

  const defaultTableState = {
    filteredInfo: {},
    sortedInfo: {},
    page: 1,
    limit: 40,
  };

  const location = useLocation();

  const adminsList = useAdminsOptions();

  const searchInputRef = useRef(null);
  const accountRole = useRef(null);

  const [data, setData] = useState([]);
  const [dataForExport, setDataForExport] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableState, setTableState] = useHistorySettings(defaultTableState);
  const [total, setTotal] = useState(0);
  const [openResellerDrawer, setOpenResellerDrawer] = useState(false);

  const [openResellerInfo, setOpenResellerInfo] = useState({
    open: false,
    resellerId: null,
  });

  const [adminId, setAdminId] = useState(null);
  useEffect(() => {
    if (location.state) {
      accountRole.current = location?.state?.accountType;
    }
  }, []);

  const [columns, setColumns] = useState([
    {
      title: "#",
      fixed: "left",
      key: "row__index",
      width: 60,
      align: "center",
      render: (text, record, index) => (
        <div onClick={(e) => e.stopPropagation()}>{record.index}</div>
      ),
    },

    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 70,
      align: "center",
      sorter: true,
      render: (text, record, index) => text,
      ...getColumnSearchProps(searchInputRef),
    },

    {
      title: "Account type",
      dataIndex: "account_type",
      key: "account_type",
      width: 150,
      align: "center",
      filters: [
        { text: "Partner", value: "partner" },
        { text: "Seller", value: "seller" },
        { text: "Reseller", value: "reseller" },
        { text: "Partner sub-reseller", value: "partner_sub_reseller" },
        { text: "Sub-reseller", value: "sub_reseller" },
      ],
      filterMultiple: false,
      render: (text, record, index) =>
        record?.is_partner && !record?.is_seller ? (
          <Tag color="gold">Partner</Tag>
        ) : record?.is_partner && record?.is_seller ? (
          <Tag color="green">Seller</Tag>
        ) : !record?.is_partner && !record?.parent_id ? (
          <Tag color="blue">Reseller</Tag>
        ) : record?.parent_id &&
          record?.status == "CAN_USE_FREE_PARTNER_CREDITS" ? (
          <Tag color="orange">Sub-reseller</Tag>
        ) : record?.parent_id &&
          record?.status == "NEED_BUY_PARTNER_CREDITS" ? (
          <Tag color="cyan">Partner sub-reseller</Tag>
        ) : (
          "N/A"
        ),
    },

    {
      title: "Brand name",
      dataIndex: "brand_name",
      key: "brand_name",
      width: 200,
      render: (text, record, index) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#f0f2f5",
          }}
        >
          <img
            src={record?.app_branding?.logo}
            style={{ width: 40, background: "black" }}
            alt=""
            onError={(error) => (error.target.src = errorImg)}
          />
          <div style={{ width: 130 }}>
            {text ? (
              <CopyText
                text={<TableText text={text} style={{ color: "#1890ff" }} />}
              />
            ) : (
              "N/A"
            )}
          </div>
        </div>
      ),
      ...getColumnSearchProps(searchInputRef),
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
      ...getColumnSearchProps(searchInputRef),
    },

    {
      title: "Surname",
      dataIndex: "surname",
      key: "surname",
      width: 200,
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
      ...getColumnSearchProps(searchInputRef),
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      align: "center",
      className: "cell-center",
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
      ...getColumnSearchProps(searchInputRef),
    },

    {
      title: "Phone",
      dataIndex: "phone_number",
      key: "phone_number",
      width: 200,
      align: "center",
      className: "cell-center",
      render: (text, record, index) =>
        text ? <TableText text={text} /> : "N/A",
    },

    {
      title: "Parent Id",
      dataIndex: "parent_id",
      key: "parent_id",
      sorter: true,
      align: "center",
      width: 120,
      render: (text, record, index) => text || "N/A",
      ...getColumnSearchProps(searchInputRef),
    },

    {
      title: "Partner key",
      dataIndex: "partner_key",
      key: "partner_key",
      sorter: true,
      align: "center",
      width: 170,
      render: (text, record, index) => text || "N/A",
      ...getColumnSearchProps(searchInputRef),
    },

    {
      title: "Server hosts",
      dataIndex: "server_host",
      key: "server_host",
      width: 130,
      align: "center",
      render: (text, record, index) =>
        record?.provider_servers.length ? (
          <Popover
            content={record.provider_servers.map((item, index) => {
              return <p>{item.host}</p>;
            })}
            title={text}
            trigger="hover"
          >
            <Button>{record.provider_servers.length}</Button>
          </Popover>
        ) : (
          "N/A"
        ),
    },

    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      width: 100,
      align: "center",
      sorter: true,
      render: (text, record, index) => text,
    },

    {
      title: "App activation type",
      dataIndex: "app_activation_type",
      key: "app_activation_type",
      width: 220,
      align: "center",
      filters: [
        { text: "Active", value: "active" },
        { text: "Free trial", value: "free_trial" },
        { text: "Sync", value: "sync" },
      ],
      render: (text, record, index) => (
        <TableText
          text={(
            text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase()
          )?.replaceAll("_", " ")}
        />
      ),
    },

    {
      title: "Device count",
      dataIndex: "device_count",
      key: "device_count",
      align: "center",
      width: 150,
      render: (text, record, index) => (
        <TableText text={record?.device_count} />
      ),
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
    },

    {
      title: "App trial days",
      dataIndex: "app_trial_days",
      key: "app_trial_days",
      width: 130,
      align: "center",
      sorter: true,
      render: (text, record, index) => text,
    },

    {
      title: "App auto renew",
      dataIndex: "app_auto_renew",
      key: "app_auto_renew",
      width: 150,
      align: "center",
      render: (text, record, index) => <TableBoolean value={text} />,
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    },

    {
      title: "App login",
      dataIndex: "app_login",
      key: "app_login",
      width: 100,
      align: "center",
      render: (text, record, index) => <TableBoolean value={text} />,
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    },
    {
      title: "Online",
      dataIndex: "is_online",
      key: "is_online",
      width: 100,
      align: "center",
      render: (text, record, index) => <TableBoolean value={text} />,
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    },
    {
      title: "Registration date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 170,
      align: "center",
      sorter: true,
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      filterDropdown: RangePickerFilterDropdown,
    },
    {
      title: "Last online date",
      dataIndex: "online_date",
      key: "online_date",
      width: 170,
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      ...getColumnDateProps(Date),
    },

    {
      title: "Blocked",
      dataIndex: "blocked",
      key: "blocked",
      width: 100,
      align: "center",
      render: (text, record, index) => (record.blocked ? "Yes" : "No"),
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    },
    {
      title: "Updated date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      width: 250,
      sorter: true,
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      filterDropdown: RangePickerFilterDropdown,
    },

    {
      title: "",
      key: "row__actions",
      fixed: "right",
      width: 60,
      align: "center",
      render: (text, record, index) => (
        <div
          onClick={() =>
            setOpenResellerInfo({ open: true, resellerId: record?.id })
          }
          className="on-row"
        >
          <RightOutlined />
        </div>
      ),
    },
  ]);

  const [onChangeColumns] = useColumns(columns, setColumns, "resellers");

  const getData = () => {
    let query = generateQuery(tableState, fieldsType);

    setLoading(true);

    let providerQuery = JSON.parse(query.query);

    if (accountType && tableState?.filteredInfo?.account_type !== null) {
      let partner = accountType.find((el) => el == "partner");
      let seller = accountType.find((el) => el == "seller");
      let reseller = accountType.find((el) => el == "reseller");
      let partner_sub_reseller = accountType.find(
        (el) => el == "partner_sub_reseller"
      );
      let sub_reseller = accountType.find((el) => el == "sub_reseller");

      if (partner || accountRole?.current == "partner") {
        providerQuery = {
          ...providerQuery,
          filter: { is_partner: true, is_seller: false },
        };
        query.query = JSON.stringify(providerQuery);
      }

      if (seller || accountRole?.current == "seller") {
        providerQuery = {
          ...providerQuery,
          filter: { is_partner: true, is_seller: true },
        };
        query.query = JSON.stringify(providerQuery);
      }

      if (reseller || accountRole?.current == "reseller") {
        providerQuery = {
          ...providerQuery,
          filter: { is_partner: false, parent_id: null },
        };
        query.query = JSON.stringify(providerQuery);
      }

      if (sub_reseller || accountRole?.current == "sub_reseller") {
        providerQuery = {
          ...providerQuery,
          filter: { status: "CAN_USE_FREE_PARTNER_CREDITS" },
          filter_notin: { provider_id: ["null"] },
        };
        query.query = JSON.stringify(providerQuery);
      }

      if (
        partner_sub_reseller ||
        accountRole?.current == "partner_sub_reseller"
      ) {
        providerQuery = {
          ...providerQuery,
          filter: { status: "NEED_BUY_PARTNER_CREDITS" },
          filter_notin: { provider_id: ["null"] },
        };
        query.query = JSON.stringify(providerQuery);
      }
    } else {
      query.query = JSON.stringify(providerQuery);
    }

    if (adminId) {
      providerQuery = {
        ...providerQuery,
        filter: { admin_id: adminId },
      };
      query.query = JSON.stringify(providerQuery);
    } else {
      query.query = JSON.stringify(providerQuery);
    }

    Request.provider
      .get(query)
      .then((data) => {
        if (!data.rows.length && tableState.page > 1) {
          return setTableState({ ...tableState, page: tableState.page - 1 });
        }
        setLoading(false);
        setData(
          data.rows.map((item, index) => ({
            index: (tableState.page - 1) * tableState.limit + index + 1,
            ...item,
          }))
        );
        setTotal(data.total);
      })
      .catch(({ message }) => {
        setLoading(false);
      });
  };

  const tableOnChange = (pagination, filters, sorter) => {
    let { order, field } = sorter;

    if (filters && filters.account_type) {
      setAccountType(filters.account_type);
    } else {
      setAccountType([]);
    }

    setTableState({
      filteredInfo: filters,
      sortedInfo: { order, field },
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const resetTable = () => {
    setTableState(defaultTableState);
  };

  useEffect(() => {
    let _columns = [...columns];
    let { filteredInfo, sortedInfo } = tableState;
    _columns.map((column) => {
      column.filteredValue =
        accountType?.length == 0 &&
        column.key == "account_type" &&
        accountRole?.current &&
        accountRole?.current !== "all-reseller" &&
        filteredInfo.account_type !== null
          ? [accountRole?.current]
          : filteredInfo[column.key] || null;

      column.sortOrder = sortedInfo.field === column.key && sortedInfo.order;

      if (column?.children) {
        column?.children?.map((child) => {
          child.filteredValue = filteredInfo[child.key] || null;
          child.sortOrder = sortedInfo.field === child.key && sortedInfo.order;
        });
      }
    });
    setColumns(_columns);
    const timeout = setTimeout(() => {
      getData();
    }, 500);
    return () => clearTimeout(timeout);
  }, [tableState, accountRole?.current, adminId]);

  const onExport = (selected) => {
    if (selected == "allData") {
      Request.provider
        .get({ query: JSON.stringify({ pagination: 0 }) })
        .then((data) => {
          setDataForExport(data);
        })
        .catch(({ message }) => {
          notification.error({ message });
        });
    }
    if (selected == "data") {
      setDataForExport(data);
    }
  };
  const onSelectAdmin = (value) => {
    setAdminId(value);
  };
  const onSearchAdmin = (value) => {
    // console.log("search:", value);
  };
  return (
    <div>
      <TitlePage title="Resellers" />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 8,
          marginBottom: 15,
        }}
      >
        <div className="tb-header">
          <div style={{ fontSize: "23px" }}>
            <span style={{ fontSize: "15px", marginRight: "10px" }}>
              Total:
            </span>
            {""}
            <span>{total}</span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",
            gap: 8,
            width: "100%",
          }}
        >
          <Select
            showSearch
            allowClear
            placeholder="Search by admin"
            optionFilterProp="label"
            onChange={onSelectAdmin}
            onSearch={onSearchAdmin}
            options={adminsList}
            style={{ width: 220 }}
          />
          <TableResetButton onClick={resetTable} />
          <TableSettingsButton
            columns={columns}
            onChangeColumns={onChangeColumns}
          />
          <TableCSV dataForExport={dataForExport} onExport={onExport} />
          <TableAddButton
            title="Add New Reseller"
            onClick={() => setOpenResellerDrawer(true)}
          />
        </div>
      </div>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns.filter((column) => column.visible)}
        dataSource={data}
        scroll={{
          x: "max-contnet",
          y: null,
        }}
        onChange={tableOnChange}
        size="small"
        pagination={{
          position: ["bottomCenter"],
          current: tableState.page,
          total: total,
          pageSize: tableState.limit,
          showSizeChanger: true,
        }}
        bordered
      />
      <NewResellerDrawer
        open={openResellerDrawer}
        onClose={() => setOpenResellerDrawer(false)}
        getData={getData}
      />

      <ResellerInfoDrawer
        resellerId={openResellerInfo?.resellerId}
        open={openResellerInfo.open}
        onClose={() => setOpenResellerInfo({ open: false, resellerId: null })}
      />
    </div>
  );
}
