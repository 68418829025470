import { Table } from "antd";
import { CheckOutlined, CloseOutlined, RightOutlined } from "@ant-design/icons";

import { useState, useEffect, useRef } from "react";
import Request from "../../Requests";

import useColumns from "../../hooks/useColumns";

import { generateQuery } from "../../Utils";

import { getColumnSearchProps } from "../../configs/config";

import {
  TableDateHHMMSS,
  TableText,
  RangePickerFilterDropdown,
  CopyText,
  DeviceInfoDrawer,
} from "../../components";

export default function Deactivated({ theProviderId }) {
  const fieldsType = {
    device_key: "STRING",
    playlist: "STRING",
    expired_date: "INTEGER",
    free_trial_expired: "INTEGER",
    auto_renew: "INTEGER",
    createdAt: "DATE",
    updatedAt: "DATE",
  };

  const defaultTableState = {
    filteredInfo: {},
    sortedInfo: {},
    page: 1,
    limit: 10,
  };

  const searchInputRef = useRef(null);

  const [openDeviceInfo, setOpenDeviceInfo] = useState({
    open: false,
    deviceKey: null,
  });

  const [columns, setColumns] = useState([
    {
      title: "#",
      fixed: "left",
      key: "row__index",
      align: "center",
      render: (text, record, index) => record.index,
    },
    {
      title: "Device key",
      dataIndex: "device_key",
      key: "device_key",
      align: "center",
      fixed: "left",
      render: (text, record, index) =>
        text ? (
          <CopyText
            text={<TableText text={text} style={{ color: "#1890ff" }} />}
          />
        ) : (
          "N/A"
        ),
      ...getColumnSearchProps(searchInputRef),
    },
    {
      title: "Device os",
      dataIndex: "device_os",
      key: "device_os",
      align: "center",
      render: (text, record, index) => <TableText text={text} />,
    },
    {
      title: "Auto renew",
      dataIndex: "auto_renew",
      key: "auto_renew",
      align: "center",
      render: (text, record, index) => (
        <TableText
          text={
            text ? (
              <span>
                <CheckOutlined style={{ color: "#2ec4b6" }} />
              </span>
            ) : (
              <span>
                <CloseOutlined style={{ color: "#e63946" }} />
              </span>
            )
          }
        />
      ),
    },
    {
      title: "Expired date",
      dataIndex: "expired_date",
      key: "expired_date",
      align: "center",
      sorter: true,
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
    },
    {
      title: "Free trial expired",
      dataIndex: "free_trial_expired",
      key: "free_trial_expired",
      align: "center",
      sorter: true,
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
    },

    {
      title: "Updated date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },

    {
      title: "Created date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) =>
        text ? <TableDateHHMMSS date={text} /> : "N/A",
      sorter: true,
      filterDropdown: RangePickerFilterDropdown,
    },
    {
      title: "",
      key: "row__actions",
      fixed: "right",
      width: 60,
      align: "center",
      render: (text, record, index) => (
        <div
          onClick={() =>
            setOpenDeviceInfo({ open: true, deviceKey: record?.device_key })
          }
          className="on-row"
        >
          <RightOutlined />
        </div>
      ),
    },
  ]);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const [tableState, setTableState] = useState(defaultTableState);

  const [total, setTotal] = useState(0);

  const [onChangeColumns] = useColumns(columns, setColumns, "Deactivated");

  const getData = () => {
    let query = generateQuery(tableState, fieldsType);

    if (theProviderId) {
      let providerQuery = JSON.parse(query.query);
      providerQuery = {
        ...providerQuery,
        filter: { provider_id: theProviderId },
      };
      query.query = JSON.stringify(providerQuery);
    }

    setLoading(true);

    Request.resller_devices
      .deactivated(query, theProviderId)
      .then((data) => {
        if (!data.rows.length && tableState.page > 1) {
          return setTableState({ ...tableState, page: tableState.page - 1 });
        }

        setLoading(false);
        setData(
          data.rows.map((item, index) => ({
            index: (tableState.page - 1) * tableState.limit + index + 1,
            ...item,
          }))
        );
        setTotal(data.total);
      })
      .catch(({ message }) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let _columns = [...columns];
    let { filteredInfo, sortedInfo } = tableState;

    _columns.map((column) => {
      column.filteredValue = filteredInfo[column.key] || null;
      column.sortOrder = sortedInfo.field === column.key && sortedInfo.order;
    });

    setColumns(_columns);

    const timeout = setTimeout(() => {
      getData();
    }, 500);
    return () => clearTimeout(timeout);
  }, [tableState, theProviderId]);

  const tableOnChange = (pagination, filters, sorter) => {
    let { order, field } = sorter;

    setTableState({
      filteredInfo: filters,
      sortedInfo: { order, field },
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  return (
    <>
      <Table
        loading={loading}
        rowKey="id"
        columns={columns.filter((column) => column.visible)}
        dataSource={data}
        scroll={{
          x: "max-content",
        }}
        bordered={true}
        onChange={tableOnChange}
        size="small"
        pagination={{
          position: ["bottomCenter"],
          current: tableState.page,
          total: total,
          pageSize: tableState.limit,
          showSizeChanger: true,
        }}
      />
      <DeviceInfoDrawer
        deviceKey={openDeviceInfo?.deviceKey}
        open={openDeviceInfo?.open}
        onClose={() => setOpenDeviceInfo({ open: false, deviceKey: null })}
      />
    </>
  );
}
